import { useState } from 'react';
import Modal from 'react-modal';
import { Cancel } from '../../../icons/Cancel';
import Button from '../../Button/Button';
import RemitaPayment from 'react-remita';
import Select from 'react-select';
import {
  createOrder,
  updateOrder,
} from '../../../redux/actions/paymentActions';
import { useDispatch, useSelector } from 'react-redux';
import { numbersOnly } from '../../../utils/utils';
import './successmodal.scss';
import TextInput from '../../Input/TextInput';
import { useNavigate } from 'react-router';

const SuccessModal = ({ modalOpen, closeModal, ato, openPasswordModal }) => {
  const dispatch = useDispatch();
  const { paymentLoading, order } = useSelector((state) => state.payment);
  const navigate = useNavigate();
  const [view, setView] = useState(1);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '400px',
    },
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  let paymentData = {
    key: process.env.REACT_APP_REMITA_KEY, // enter your key here
    processRrr: true,
    customerId: '',
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    amount: order?.total,
    narration: `payment from school${user?.school?.name}`,
  };

  const pushRoute = () => {
    navigate(ato ? '/ato' : '/students-list');
    closeModal();
  };

  const passwordModal = () => {
    if (user?.school?.activated) {
      return;
    } else {
      openPasswordModal();
    }
  };

  function makePayment() {
    var form = document.querySelector('#payment-form');
    var paymentEngine = window.RmPaymentEngine.init({
      key: process.env.REACT_APP_REMITA_KEY,
      processRrr: true,
      transactionId: order?.provider_reference, // Replace with a reference you generated or remove the entire field for us to auto-generate a reference for you. Note that you will be able to check the status of this transaction using this transaction Id
      extendedData: {
        customFields: [
          {
            name: 'rrr',
            value: order?.provider_reference,
          },
        ],
      },
      onSuccess: function (response) {
        dispatch(
          updateOrder(
            user?.school?.id,
            {
              providerReference: order?.provider_reference,
            },
            token,
            order?.reference,
            pushRoute(),
            ato
          )
        );
        passwordModal();
      },
      onError: function (response) {
        console.log('callback Error Response', response);
      },
      onClose: function () {
        console.log('closed');
      },
    });
    paymentEngine.showPaymentWidget();
  }
  let data = {
    ...paymentData,
    extendedData: {
      customFields: [
        {
          name: 'rrr',
          value: order?.provider_reference, //rrr to be processed.
        },
      ],
    },
    onSuccess: function (response) {
      dispatch(
        updateOrder(
          user?.school?.id,
          {
            providerReference: order?.provider_reference,
          },
          token,
          order?.reference,
          pushRoute(),
          ato
        )
      );
      passwordModal();
    },
    onError: function (response) {
      // function callback when payment fails
      console.log('callback Error Response', response);
    },
    onClose: function () {
      // function callback when payment modal is closed
    },
  };

  const options = [
    {
      value: ato ? '1' : '2',
      label: ato ? 'ATO - NGN 200,000' : 'SEMAAS Number - NGN 500 / Student',
    },
    // {
    //   value: '3',
    //   label: 'Print Identity Card - NGN 500 / Student',
    // },
  ];
  const [selectedOptions, setSelectedOptions] = useState();

  const [state, setState] = useState({
    noOfStudents: 0,
    term: '',
  });

  const [errors, setErrors] = useState({
    noOfStudents: '',
    term: '',
  });

  const handleSelect = (data) => {
    setSelectedOptions(data);
  };

  const handleChange = (evt) => {
    const value = evt.target.value;

    if (evt.target.name === 'noOfStudents' && value !== '') {
      if (numbersOnly(value) === false) {
        setErrors({
          ...errors,
          [evt.target.name]: `Field can only contain numbers`,
        });
      } else {
        setErrors({
          ...errors,
          [evt.target.name]: ``,
        });
      }
    }

    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const selectValue = (option, name) => {
    setState({ ...state, [name]: option });
  };

  let orderData = {
    provider: 1,
    items: [
      selectedOptions?.some((e) => e.value === '1') && {
        bill: 1,
        units: 1,
      },
      selectedOptions?.some((e) => e.value === '2') && {
        bill: 2,
        term:
          state?.term?.toLowerCase() === 'first term'
            ? 1
            : state?.term?.toLowerCase() === 'second term'
            ? 2
            : 3,
        units: state?.noOfStudents,
      },

      selectedOptions?.some((e) => e.value === '3') && {
        bill: 3,
        term:
          state?.term?.toLowerCase() === 'first term'
            ? 1
            : state?.term?.toLowerCase() === 'second term'
            ? 2
            : 3,
        units: state?.noOfStudents,
      },
    ],
  };

  const moveToNextView = () => {
    setView(2);
  };
  const initCreateOrder = () => {
    let data = {
      provider: orderData.provider,
      items: orderData.items?.filter((el) => el !== false),
    };
    dispatch(createOrder(user?.school?.id, data, token, moveToNextView));
  };

  return (
    <Modal
      isOpen={modalOpen}
      style={customStyles}
      onRequestClose={closeModal}
      contentLabel='Example Modal'
    >
      <div className='modal-cancel' onClick={() => closeModal()}>
        <Cancel />
      </div>

      {view === 1 ? (
        <div className='login-modal'>
          <div className='login-modal__heading'>
            <h2>Please make payment to continue</h2>
            <p>Select item you want to pay for</p>
          </div>

          <Select
            options={options}
            placeholder='Select Items you want to pay for'
            isMulti
            value={selectedOptions}
            onChange={handleSelect}
          />

          {selectedOptions?.some((e) => e.value === '2' || e.value === '3') && (
            <div style={{ marginTop: '15px' }}>
              <TextInput
                label='Number of students'
                fieldClass={'input-field'}
                placeholder='Please enter number of students being paid for'
                name='noOfStudents'
                value={state.noOfStudents}
                handleChange={handleChange}
                type='number'
                errorClass={'error-msg'}
                errorMessage={errors['noOfStudents']}
              />

              <TextInput
                label='Term to be paid for'
                placeholder='Select terms your school is paying for'
                name='term'
                value={state.term}
                onSelectValue={selectValue}
                type='dropdown'
                options={[
                  { value: 'first term', label: 'first term' },
                  { value: 'second term', label: 'second term' },
                  { value: 'third term', label: 'third term' },
                ]}
                errorClass={'error-msg'}
              />
            </div>
          )}

          <div className='double-btn-flex'>
            {!ato && (
              <Button
                btnClass={'secondary-button'}
                onClick={() => closeModal()}
                btnText={<p style={{ color: '#4b8e64' }}>Pay later</p>}
              />
            )}

            <Button
              btnClass={'primary-button'}
              onClick={() => initCreateOrder()}
              btnText={paymentLoading ? 'Please wait...' : 'Confirm Payment'}
              disabled={
                !selectedOptions || paymentLoading || errors?.noOfStudents
              }
            />
          </div>
        </div>
      ) : (
        <div className='login-modal'>
          <div className='login-modal__heading'>
            <h2>Summary (RRR)</h2>
            <h3 style={{ marginBottom: '20px' }}>
              {order?.provider_reference}
            </h3>
            <h3>Total - {`NGN ${Number(order?.total)?.toLocaleString()}`}</h3>
          </div>
          <div className='double-btn-flex'>
            <Button
              btnClass={'secondary-button'}
              onClick={() => setView(1)}
              btnText={<p style={{ color: '#4b8e64' }}>Back</p>}
            />
            <Button
              btnClass={'primary-button'}
              onClick={() => makePayment()}
              btnText={
                <p>{`Pay N${Number(order?.total)?.toLocaleString()}`}</p>
              }
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default SuccessModal;
