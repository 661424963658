import './payment.scss';
import Modal from 'react-modal';
import { Cancel } from '../../../icons/Cancel';
import { useState } from 'react';
import Button from '../../Button/Button';
import RemitaPayment from 'react-remita';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrder } from '../../../redux/actions/paymentActions';
import image from '../../../assets/success.svg';
import { useNavigate } from 'react-router';

const PaymentModal = ({ modalOpen, closeModal }) => {
  const { paymentLoading, order } = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '500px',
    },
  };

  let paymentData = {
    key: process.env.REACT_APP_REMITA_KEY, // enter your key here
    customerId: '',
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    amount: order?.total,
    narration: `payment from school${user?.school?.name}`,
  };

  const showSuccessModal = () => {
    closeModal();
    setSuccess(true);
  };

  let data = {
    ...paymentData,
    extendedData: {
      customFields: [
        {
          name: 'rrr',
          value: order?.provider_reference, //rrr to be processed.
        },
      ],
    },
    onSuccess: function (response) {
      dispatch(
        updateOrder(
          user?.school?.id,
          {
            providerReference: order?.provider_reference,
          },
          token,
          order?.reference,
          showSuccessModal()
        )
      );
    },
    onError: function (response) {
      // function callback when payment fails
      console.log('callback Error Response', response);
    },
    onClose: function () {
      // function callback when payment modal is closed
    },
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    navigate('/students-list');
  };

  function makePayment() {
    var form = document.querySelector('#payment-form');
    var paymentEngine = window.RmPaymentEngine.init({
      key: process.env.REACT_APP_REMITA_KEY,
      processRrr: true,
      transactionId: order?.provider_reference, // Replace with a reference you generated or remove the entire field for us to auto-generate a reference for you. Note that you will be able to check the status of this transaction using this transaction Id
      extendedData: {
        customFields: [
          {
            name: 'rrr',
            value: order?.provider_reference,
          },
        ],
      },
      onSuccess: function (response) {
        dispatch(
          updateOrder(
            user?.school?.id,
            {
              providerReference: order?.provider_reference,
            },
            token,
            order?.reference,
            showSuccessModal()
          )
        );
      },
      onError: function (response) {
        console.log('callback Error Response', response);
      },
      onClose: function () {
        console.log('closed');
      },
    });
    paymentEngine.showPaymentWidget();
  }
  return (
    <>
      <Modal
        isOpen={modalOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className='login-modal'>
          <div className='login-modal__heading'>
            <h2>Payment confirmation (RRR)</h2>
            <h3 style={{ marginBottom: '20px' }}>
              {order?.provider_reference}
            </h3>
            <h3>Total - {`NGN ${Number(order?.total)?.toLocaleString()}`}</h3>
          </div>
          <div className='double-btn-flex'>
            <Button
              btnClass={'secondary-button'}
              onClick={() => closeModal()}
              btnText={<p style={{ color: '#4b8e64' }}>Back</p>}
            />
            <Button
              btnClass={'primary-button'}
              onClick={() => makePayment()}
              btnText={
                <p>{`Pay N${Number(order?.total)?.toLocaleString()}`}</p>
              }
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={success}
        style={customStyles}
        onRequestClose={closeSuccessModal}
      >
        <div className='payment-success'>
          <div
            className='payment-success__close'
            onClick={() => {
              navigate('/students-list');
            }}
          >
            <Cancel />
          </div>
          <img src={image} alt='' />
          <div className='payment-success__content'>
            <h2>Payment Confirmed!</h2>
            <p>
              We have received your payment, kindly check your dashboard for
              status on your ATO request
            </p>

            <Button
              btnClass={'primary-button w-100'}
              //  onClick={() => closeModal()}
              onClick={() => {
                closeSuccessModal();
              }}
              btnText={'Go to Dashboard'}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentModal;
